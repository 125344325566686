import { useState } from 'react'

export default function SmoothImage (props) {
  const [loaded, setLoaded] = useState(false)

  const duration = 250
  const easing = 'ease-in'

  const transition =
    `opacity ${props.duration || duration}ms ${props.easeing || easing}`

  const style = {
    transition,
    opacity: loaded ? 1 : 0
  }

  return (
    <img style={ style } { ...props }
      onLoad={ () => setLoaded(true) }
      alt={ props.alt } /> // because eslint warning
  )
}
