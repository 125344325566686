import { useEffect, useRef } from 'react'
import Parallax from 'parallax-js'
import SmoothImage from '../SmoothImage'

export default function ParallaxImagesContainer ({ layers, className }) {
  const sceneRef = useRef()

  useEffect(() => {
    new Parallax(sceneRef.current)
  }, [])

  return (
    <div className={ className } ref={ sceneRef }>
      {
        layers.map((layer, index) => (
          <SmoothImage key={ index }
            className={layer.className }
            data-depth={ layer.depth }
            src={ layer.image }
            alt="" />
        ))
      }
    </div>
  )
}
