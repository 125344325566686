import './App.scss'
import SmoothImage from './components/SmoothImage'
import Emoji from './components/Emoji'
import ParallaxImagesContainer from './components/ParallaxImagesContainer'

const mockupImgUrl = 'img/mockup-min.png'

const links = {
  channel: 'https://t.me/fa_sales_eu/',
  post: 'https://t.me/fa_sales_eu/51'
}

const parallaxLayers = [
  {
    className: 'money-with-wings',
    image: 'img/money-with-wings-min.png',
    depth: '1.25'
  }
]

function App () {
  return (
    <div className="App">
      <SmoothImage className="background" src="img/gradient@0.5x-min.png" alt="" />

      <div className="left-side">
        <div className="mockup-container">
          <link rel="preload" as="image" href={ mockupImgUrl } />

          <SmoothImage className="mockup-container__mockup-image"
            src={ mockupImgUrl } alt="" />

          <div className="mockup-container__bg-images">
            <SmoothImage className="bg-images__womens-clothes"
              src="img/womens-clothes-blured-min.png" alt="" />

            <SmoothImage className="bg-images__t-shirts"
              src="img/t-shirts-blured-min.png" alt="" />
          </div>
        </div>
      </div>

      <div className="right-side">
        <h1 className="headline">
          Find many<br/>
          sweet deals<br/>
          in our&nbsp;
          <a href="https://t.me/fa_sales_eu">Telegram</a>
        </h1>

        <div className="post-container">
          <ParallaxImagesContainer className="parallax-container"
            layers={ parallaxLayers } />

          <div className="post-container__post">
            <a className="post__channel-name"
              href={ links.channel }>
              <Emoji imgUrl="img/globe-min.png" />&nbsp;
              <span>/fa/ sales eu</span>
            </a>

            <div className="post__text">
              <Emoji imgUrl="img/sushi-min.png" />&nbsp;

              Dunk Highs «Salmon» for retail<br/><br/>
              Sizes: EU 35.5 / 40.5 / 42 / 43<br/><br/>
              Price — €110 / 6300 RUB<br/><br/>
              Link —&nbsp;

              <a href="https://go.fasales.ru/salmons">
                https://go.fasales.ru/salmons
              </a>
            </div>

            <a href={ links.post }>
              <SmoothImage className='post__image'
                src="img/post-image-min.jpg" alt="" />
            </a>

            <div className="post__meta">
              <a href={ links.post }>{ links.post.replace(/https:\/\//, '') }</a>
              <span>Jun 26 at 13:45</span>
            </div>
          </div>

          <div className="post-container__bg-images">
            <SmoothImage className="bg-images__person"
              src="img/person-min.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
